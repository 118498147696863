<template>
  <div class="payment payment-completed">
    <v-container v-if="order">
      <CategoryTitle
        :category="category"
        v-if="
          order.orderStatusId !== 11 &&
            order.orderStatusId != 2 &&
            order.orderStatusId != 1
        "
      />
      <div
        class="d-flex align-center flex-row flex-wrap title-wrap"
        v-if="paymentFailed"
      >
        <h1>
          Pagamento fallito
        </h1>
      </div>
      <v-card class="text-left">
        <v-card-text v-if="paymentFailed">
          <p>
            Ti informiamo che il pagamento per il tuo ordine
            <router-link
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >{{ order.orderId }}</router-link
            >
            non è andato a buon fine.
            <br />
            Puoi ritentare il pagamento, diversamente il tuo ordine verr&agrave;
            annullato automaticamente a breve
          </p>
        </v-card-text>
        <v-card-text
          class="payment-completed-text"
          v-if="order.orderStatusId === 3"
        >
          <template v-if="order.shippingAddress.deliveryServiceId === 1">
            <p>
              Al momento del ritiro, ti verrà richiesto il seguente numero
              ordine:
              <router-link
                :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >
                {{ order.orderId }}
              </router-link>
            </p>
            <p>
              Ti ricordiamo che, nella sezione "I miei ordini", potrai
              modificare o annullare il tuo ordine fino a quando non sarà in
              stato "In preparazione".
            </p>
            <p>
              Punto vendita selezionato per il ritiro:
              <strong>
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}</strong
              >
            </p>
          </template>
          <template v-else-if="order.shippingAddress.deliveryServiceId === 2">
            <p>
              Numero ordine:
              <router-link
                :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >
                {{ order.orderId }}
              </router-link>
            </p>

            <p>
              Puoi visualizzare il dettaglio del tuo ordine entrando nella
              sezione "I miei ordini". Ti ricordiamo di essere presente
              all'indirizzo indicato nell'orario selezionato per la consegna
              della tua spesa.
            </p>

            <p>
              Indirizzo selezionato per la consegna:
              <strong>
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}
              </strong>
            </p>
          </template>
          <template
            v-else-if="order.shippingAddress.deliveryServiceId === 10002"
          >
            <p>
              Numero ordine:
              <router-link
                :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >
                {{ order.orderId }}
              </router-link>
            </p>

            <p>
              Puoi visualizzare il dettaglio del tuo ordine entrando nella
              sezione "I miei ordini". Per ritirare la tua spesa utilizza il QR
              Code o il codice locker contenuti nel dettaglio dell'ordine e
              nella mail che ti abbiamo appena inviato.
            </p>

            <p>
              Locker selezionato:
              <strong>
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".list",
                    order.shippingAddress
                  )
                }}
              </strong>
            </p>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed color="primary" x-large v-bind:to="'/'">
            Torna alla homepage
          </v-btn>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-btn
            color="primary"
            class="justify-self-md-end"
            outlined
            x-large
            v-if="paymentFailed"
            @click="toggleShowPayment"
          >
            Ritenta pagamento
          </v-btn>

          <v-btn
            v-bind:to="'/profile/orders/' + order.orderId"
            color="primary"
            class="justify-self-md-end"
            outlined
            right
            x-large
          >
            Visualizza dettaglio ordine
          </v-btn>
        </v-card-actions>
      </v-card>
      <PaymentTypeList
        mode="order"
        :order-id="order.orderId"
        v-if="showPayment"
        :options="paymentTypeListOptions"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.v-card {
  padding: 10px;
}
</style>
<script>
import OrderService from "~/service/orderService";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";
import PaymentTypeList from "~/components/payment/PaymentTypeList";
import AnalyticsService from "~/service/analyticsService";
import { mapActions, mapGetters } from "vuex";

import clone from "lodash/clone";
export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: { CategoryTitle, PaymentTypeList },
  data() {
    return {
      order: null,
      showPayment: false,
      ordersSentToAnalytics: [],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  computed: {
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    },
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      updateOrdersSentToAnalytics: "app/updateOrdersSentToAnalytics",
      updateCurrentOrderId: "app/updateCurrentOrderId"
    }),
    async fetchOrder(orderId) {
      let _this = this;
      console.log(_this);
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!_this.ordersSentToAnalytics.includes(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            if (window.dataLayer && this.user && this.user.email) {
              window.dataLayer.push({ e_mail: this.user.email });
            }
            _this.ordersSentToAnalytics.push(orderId);
            if (_this.ordersSentToAnalytics.length > 20) {
              _this.ordersSentToAnalytics.shift();
            }
            _this.updateOrdersSentToAnalytics(_this.ordersSentToAnalytics);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    }
  },
  mounted() {
    this.ordersSentToAnalytics = clone(
      this.$store.state.app.ordersSentToAnalytics
    );
    this.fetchOrder(this.$route.params.orderId);
    //rimuovo CURRENT_ORDER_ID dal local storage
    if (localStorage.currentOrderId) {
      this.updateCurrentOrderId(null);
    }
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
